<template>
  <div
      @mouseover="isSelectOpened = true"
      @mouseleave="isSelectOpened = false"
      class="locale-switcher"
      v-if="!isMobile"
      style="background: transparent !important;"
  >
    <div style="display: flex; align-items: center;">
      <img style="width: 13px; margin-right: 5px;" :src="'/' + $i18n.locale + '.png'" alt="">
      <span :class="{ hovered: isSelectOpened }" class="current-language">{{ languagesList.find(x => x.locale === $i18n.locale).title }}</span>
      <font-awesome-icon
          icon="chevron-down"
          size="xs"
          class="select-arrow"
      />
    </div>
    <transition name="fade">
      <div class="language-list" v-show="isSelectOpened">
        <li @click="changeLanguage(language.locale)" v-for="language, index in languagesList" :key="index">
          <img :src="'/' + language.locale + '.png'" alt=""> {{ language.title }}
        </li>
      </div>
    </transition>
  </div>
  <ul v-else style="background: transparent !important;">
      <li @click="changeLanguage(language.locale)" v-for="language, index in languagesList" :key="index">
        <img :src="'/' + language.locale + '.png'" alt=""> {{ language.title }}
      </li>
  </ul>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LocaleSwitcher",
  data() {
    return {
      isSelectOpened: false,
      languagesList: [
        {
          title: 'English',
          locale: 'en'
        },
        {
          title: '简体中文',
          locale: 'zh'
        },
        {
          title: '한국어',
          locale: 'kr'
        }
      ]
    }
  },
  computed: {
    ...mapState('Index', [
      'isMobile'
    ])
  },
  methods: {
    async changeLanguage(locale) {
      if (this.$i18n.locale === locale) return;
      this.$i18n.locale = locale;
      localStorage.setItem("locale", locale);
      await this.$router.push({ params: { locale } });
      this.$router.go(0);
    }
  }
}
</script>

<style lang="scss" scoped>

.locale-switcher.mains {
  .language-list { background: #0D151D; }
  li { color: rgb(216, 214, 211); }
}

.locale-switcher.dark {
  background: #1c1e1f;
  .language-list { background: #1c1e1f; }
  li { color: rgb(216, 214, 211); }
}

ul.mains, ul.dark {
  li { color: rgb(216, 214, 211); }
}
.locale-switcher {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0px 15px;
  height: 60px;
  z-index: 999;
}

.current-language {
  margin-right: 5px;
  cursor: pointer;
  transition: .3s;
}

.current-language.hovered, .current-language.hovered ~ svg {
  color: #2A85FF;
}

.select-arrow {
  transition: .3s;
}

.current-language.hovered ~ .select-arrow {
  transform: rotate(180deg);
}

li {
  display: flex;
  height: 42px;
  align-items: center;
  color: #0e1118;
  white-space: nowrap;
  cursor: pointer;
  transition: color .5s ease;
  font-size: 14px;
  font-weight: 600;
}

li:hover {
  color: #2A85FF !important;
}

li img {
  width: 16px;
  margin-right: 12px;
}

.language-list {
  display: block;
  position: absolute;
  top: 60px;
  right: 0;
  width: 160px;
  padding: 22px 26px;
  box-sizing: border-box;
  box-shadow: 0 4px 12px 0 rgba(0, 1, 4 , 0.1);
  font-size: 14px;
  background: white;
}
</style>